
<script setup lang="ts">
    import { computed } from "vue";
    import { OActionList, OActionItem } from "o365-ui-components";
    import { isMobile } from "o365.GlobalState.ts";
    import type { DataItemModel } from "o365-dataobject";

    export interface IProps {
        row: DataItemModel;
        title?: string;
        loading?: boolean;
    };

    const props = defineProps<IProps>();

    const dataObject = computed(() => props.row.$.dataObject);

    async function deleteItem() {
        if (isMobile.value) {
            if (!confirm($t("Are you sure you want to delete") + ":\n" + props.title)) {
                return;
            }
        }
        await props.row.delete();
    }
</script>

<template>
    <OActionList :title="title">
        <template #target="{ target }">
            <OButton :target="target" variant="link" color="black" :icon="isMobile ? 'bi-three-dots' : 'bi-chevron-down'" :loading="loading" />
        </template>
        <slot />
        <OActionItem class="text-danger" :text="$t('Delete')" icon="bi-trash" :disabled="!dataObject.allowDelete" @click="deleteItem()" />
    </OActionList>
</template>
